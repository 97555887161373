@import url('https://fonts.googleapis.com/css2?family=Almarai&family=Kanit:wght@300&display=swap');
*{
    font-family: 'Kanit';
    background-color: #FFF;
}
::-webkit-scrollbar{
    width: 0.6vw;
}
::-webkit-scrollbar-track{
    background-color: #FFF;
}
::-webkit-scrollbar-thumb{
    border-radius: 5px;
    background-color: #6FAB3A;
}

