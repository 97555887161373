.navbar_mobile{
    display: none;
    
}
.nabar_holer{
    width: 99.2vw;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #016505;
    overflow: hidden;
    
}
.navbar_content_holder{
    /* border: red 2px solid; */
    width: 100%;
    height: 80%;
    display:flex;
    align-items: center;
    justify-content: space-between;
    gap:60px;
    /* font-size: 23px; */
    font-weight: 600;
}
.image_container{
    width: 15%;
    height: 80%;
    /* align-self: center; */
    /* border: 2px slateblue solid; */
    margin:5%;
}
.image_container img{
    width:100%;
    height: 100%;
}
.text_contain_nav{
    width:50%;
    height:50%;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    /* border: 2px salmon solid; */
    color: #016505;
    font-size: 1.5vw;
}
.navbar_content_holder a{
    /* font-size: 1.6em; */
    font-weight: 600;
    color: #016505;
    text-decoration:none;
    cursor: pointer;
    font-weight: 600;
    
}
.navbar_content_holder a:hover{
    color: black;
    text-decoration:underline;
}

@media screen and (max-width: 767px) {
    /* CSS styles for screens smaller than 768px */
    /* ... */
    .nabar_holer{
        flex-direction: column;
    }
    .navbar_mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% ;
        /* border: 1px solid red; */
        margin-top: 15%;
    }
    .image_container img{
        display: none;
    }
    .navbar_mobile_ham img{
        width: 200%;
        height: 200%;
        transform: translateX(-90%);
    }
    .navbar_content_holder{
        /* border: 1px solid red; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding-top: 15%; */
    }
    .text_contain_nav{
        width: 90%;
        height: fit-content;
        margin-top: -20%;
        font-size: 2vh;
        font-weight: bold;
        gap: 3%;
        /* border: 1px solid red; */
        margin-bottom: 5%;
    }
    .navbar_mobile_logo img{
        width:50%;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    /* CSS styles for screens between 768px and 991px */
    /* ... */
    .navbar_mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0% 2%;
    }
    
    .navbar_content_holder{
        display: none;
        
    }
    .navbar_mobile_logo img{
        width:50%;
    }

}