.contact_container{
    width: 99.2vw;
    height: 30vw;
    /* background: #c5c6d0; */
    /* border: 1px solid red; */
    display:flex;
    align-items: center;
    justify-content: center;
}

.contact_content{
    display:flex;
    width: 60%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    font-size: 1.2vw;
    font-weight: 600;
    background-color: transparent;

    
}
.contact_content h2{
    font-size: 2.1vw;
    font-weight: 800;
    background-color: transparent;
    
}
.contact_content h3{
    font-weight: 800;
    font-size: 1.6vw;
    margin-top: -1.5%;
    
    background-color: transparent;
    
}
#thrid_para{
    width: 75%;
    text-align: center;
    font-size: 1.3vw;
    margin: -3% 0;
    background-color: transparent;
    /* line-height: ; */
    /* border: #6FAB3A solid 2px; */
}
.contact_content p{
    margin-top: 5%;
    font-size:1.1vw;
    font-weight: 600;
    background-color: transparent;
    text-align: center;
    font-size: 1.2vw;
    
}
.contact_content span{
    color: #016505;
    font-weight: 600;
    background-color: transparent;
}

@media screen and (max-width: 767px) {
    .contact_container{
        width: 99.2vw;
        height: 60vh;
        /* border: 2px solid red; */
    }
    .contact_content{
        line-height: 2vh;
    }
    .contact_content h2{
        font-size: 2.8vh;
        
    }
    .contact_content h3{
        font-size: 2.4vh;
        
    }
    #thrid_para{
        width: 45vh;
        font-size: 2.0vh;
        line-height: 22px;
        /* border: 1px solid red; */
    }
    .contact_content p{
        padding-top:2%;
        font-size: 1.9vh;
        line-height: 22px;
        width: 42vh;
        
    }
}