
.About_Us_Screen{
    width: 99.2vw;
    height: 100vh;
    /* background-image: url('../../res/about_us_bg.jpeg'); */
    /* font-style: italic; */
    overflow: scroll;
    font-size: 1.6vw;
    letter-spacing: 1px;
    line-height: 0.5;
    
}
.about_us_content_wrapper{
    width: 90%;
    /* height: 100%; */
    background: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}
.about_us_content{
    /* border: 2px solid red; */
    width: 90%;
    height: 60%;
    background: none;
    margin:2% 5%;
    /* line-height: %; */
}

.about_us_content p{
    background: none;
    line-height: 1.8rem;
    text-align: justify;
    font-weight: 500;
}

.about_us_content span{
    background: none;
    font-weight: 600;
}
.about_us_content h3{
    color: #016505;
    background: none;
    font-weight: 600;
    margin-top: 3%;
}

.about_us_icon_section_wrapper{
    /* border: 2px solid red; */
    width: 90%;
    height: 80%;
    background: none;
    margin: 2% 5%;
}
.about_us_icon_section_wrapper_container{
    width: 100%;
    height: 20%;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    /* border: 2px solid #6FAB3A; */
    background:none;
    margin: 1%;
    transition: all .3s ease-in-out;
    &:hover {
        transform: scale(1.1);
        
    }
}
.about_us_icon_section_wrapper_container img{
    width: 7%;
    /* height:100%; */
    margin:  1%;
    background: none;
}
.about_us_icon_section_wrapper_text{
    background: none;
    width: 100%;
}
.about_us_icon_section_wrapper_text h2,h3{
    background: none;
    
}
.about_us_icon_section_wrapper_text h2{
    font-size: 1.6vw;
    font-weight: 600;
    margin: 1%;
}
.about_us_icon_section_wrapper_text h3{
    margin: 1%;
    font-size:1.5vw;
    line-height: 1.2;
    font-weight: 500;
}
@media screen and (max-width: 767px) {
   .about_us_content h3{
    font-size: 3vh;
    line-height: 1.2;
    margin-bottom: 5%;
   }
   .about_us_content p{
    width: 90vw;
    /* border: 1px solid red; */
    line-height: 18px;
    margin-top: -5px;
    font-size: 1.8vh;
   }
   .about_us_icon_section_wrapper_text{
    width: 90vh;
   }
   .about_us_icon_section_wrapper_text h2{
    font-size: 2vh;
    line-height:18px
   }
   .about_us_icon_section_wrapper_text h3{
    font-size: 1.6vh;
   }
   .about_us_icon_section_wrapper_container img{
    width: 10%;
    margin-right: 5%;
   }
}