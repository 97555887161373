.footer_main_container {
    border-top: 2px solid #6FAB3A;
    width: 99.2vw;
    height: 50vh;
    display: flex;
    background-color: #FFF;
    flex-direction: column;
    /* border: 2px solid black; */
    justify-content: center;
    font-style: italic;
    overflow: hidden;
    padding-bottom: 5%;
    justify-content: flex-start;
    gap: 5%;
    
}

.row_one {
    /* margin-top: 0.5%; */
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    justify-content: flex-start;
    align-items: center;
    /* gap: 3%; */
    /* margin: 0 2%; */
    /* justify-content: center; */
}

.mid_content {
    /* border: 2px solid red; */
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mid_content a {
    color: #016505;
    text-decoration: none;

    list-style: none;
    margin-top: 1%;
    /* line-height: 30px; */

}

.mid_content h3:hover {
    cursor: default;
}

.mid_content a :hover {
    color: black;
}

.mid_content :hover {
    cursor: pointer;
}

.img_holder {
    /* border: 2px solid red; */
    height: 60%;

}

.social_handler {
    height: 40%;
    display: flex;
    gap: 15px;
    justify-content: center;
    
    
}
.social_handler a{
    width: fit-content;
    height: fit-content;
    /* border: 1px solid red; */
}
.social_handler img {
    /* border: 2px solid green; */
    width: 40%;
    background: transparent;
    height: 40%;
    transition: all .3s ease-in-out;
    filter: drop-shadow(-10px 9px 15px #c0bebe);

    &:hover {
        transform: scale(1.1);
        cursor: pointer;

    }
}

.img_holder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.row_two {
    margin-top: -4.2%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    /* margin: 0 2%; */
    justify-content: center;
    align-items: center;
}

.row_two p {
    width: 90%;
    /* border: 2px solid red; */
    text-align: justify;

}

.row_two h3 {
    font-family: Arial;
    font-weight: 600;
    font-size: 1.2vw;
    margin-top: -0.5%;
}

.message_section {
    width: 30%;
    height: 70%;
    /* border: 2px solid red; */
    margin: 1% 2%;
    display: flex;
    flex-direction: column;
    text-align: center;
    /* font-size: 1vw; */
    font-weight: 600;
}

.message_section h1 {
    color: #016505;
    font-weight: 600;
    font-size: 1.8vw;
    margin: 1%;
    /* border: 2px solid red; */
    width: 100%;
    align-self: center;
}

.message_section input {
    text-align: center;
    width: 80%;
    height: 12%;
    margin: 1%;
    font-weight: 600;
    font-size: 1.2vw;
    align-self: center;
    border-radius: 5px;
    border: 1px solid #016505;
    background-color: rgb(255, 255, 255);
    color: #016505;
}

.message_section input:nth-child(4) {
    height: 100%;
}

.message_section input::placeholder {
    color: #016505;
    font-weight: 600;
    opacity: 1;
}

.message_section button {
    width: 30%;
    height: 10%;
    padding: 3.5% 0;
    border-radius: 3px;
    align-self: flex-start;
    margin-left: 10%;
    margin-top: 1%;
    margin-bottom: 1%;
    background-color: #016505;
    border: none;
    color: #FFF;
    font-size: 1.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    /* box-shadow: 3px 3px #bebebe; */
}

.message_section button:hover {
    box-shadow: none;
    border: 2px solid #016505;
    cursor: pointer;
    background-color: #FFF;
    font-weight: 600;
    color: #016505;
}
@media screen and (max-width: 767px) {
    /* CSS styles for screens smaller than 768px */
    /* ... */
    .footer_main_container{
        width: 99.2vw;
        height: fit-content;
        flex-direction: column;
        margin-top: 10%;
        /* border: 1px solid red; */
        padding-top: 10%;
    }
    .row_one{
        margin-top: -2%;
        flex-direction: column;
        /* border: 2px solid red; */
        
        
    }
    .message_section{
        /* border: 2px solid red; */
        width: 100%;
        margin-top: 1%;

    }
    .message_section h1{
        font-size: 2.5vh;
    }
    .message_section input{
        width: 80%;
        height: 10%;
        font-size: medium;
    }
    #message_input{
        height: 10vh;
    }
    .message_section button{
        width: 60%;
        height: 2vh;
        /* border: 1px solid red; */
        align-self: center;
        margin-left: -1%;
        font-size: medium;
        padding: 5% 0;
        margin-bottom: 10%;
        border-radius: 10px;
        
    }
    .img_holder{
        margin-top: -3%;
        background: transparent;
    }
    .img_holder img{
        width: 99.2%;
        height: fit-content;
        aspect-ratio: 16/9;
        /* border: 1px solid red; */
        /* padding-bottom: 50%; */
        /* margin-bottom: 10%; */
    }
    .row_two{
        /* margin-top:-50%; */
        width: 100%;
        padding-bottom: 10%;
        /* border: 2px solid red; */
    }
    .row_two p{
        font-size: 1.7vh;
        margin-bottom: 2%;
        /* padding-bottom: -2%; */
    }
    .row_two h3{
        /* padding-top: 5%; */
        font-size: 2vh;
    }
    .mid_content{
        width: 99.5%;
        /* padding-bottom: 10%; */
        /* border: 1px solid red; */
    }
    .social_handler{
        transform: translateY(-50%);
        /* padding-top: 10%; */
        width: 99.2%;
        height: 2vw;
        padding-bottom: 9%;
        gap: 1px;
        /* border: 1px solid red; */
    }
    .social_handler img{
        width: 40%;
        /* border: 1px solid red; */
    }

}
