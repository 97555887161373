@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0px);
    }
}

.modal_container_home{
    display: none;
    overflow: hidden;
}

.Section_one {
    width: 99.2vw;
    overflow: hidden;
    height: 80vh;
    /* border: 1px solid teal; */
    font-style: italic;
    
    
}

.home_img_holder {
    width: 99.8%;
    aspect-ratio: 16/9;
    height: 82%;
    overflow: hidden;
    position: absolute;
    /* background-image: url(../../res//display_bg.jfif); */
    background-repeat: no-repeat;
    background-size: 100% 100%;

}

.button_holder {
    width: 15%;
    height: 5%;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    border-radius: 20px;
    top: 80%;
    left: 65%;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.button_holder button {
    width: 100%;
    height: 100%;
    font-size: 1.4vw;
    font-weight: 600;
    border: none;
    border-radius: 20px;
    background-color: #fff;


}

.button_holder button:hover {
    cursor: pointer;
    /* background-color: #6FAB3A; */
    /* color: #6FAB3A; */
    color: #016504;
}

.Section_two {
    /* margin-top: 45%; */

    width: 99.2vw;
    height: 100vh;
    overflow: hidden;
    /* border: black 2px solid; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-style: italic;
}

.grid_text {
    color: #016505;
    width: 100%;
    height: 10%;
    font-weight: 600;
    font-size: 2vw;
    letter-spacing: 1px;
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid_layout_wrapper {
    width: 80%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* flex-direction: row; */
    /* margin-bottom: 10px; */
    /* border: 2px solid black; */
    overflow: hidden;
    padding-bottom: 2%;

}

.gird {
    /* border: 2px solid red; */
    width: 40%;
    height: 45%;
    font-weight: 600;
    margin: 1% 2%;
    font-size: 1.8vw;
    color: transparent;
    display: flex;
    justify-content: center;
    transition: all .3s ease-in-out;
    filter: drop-shadow(-10px 9px 15px #c0bebe);
    border-radius: 10px;

    &:hover {
        transform: scale(1.1);
        cursor: pointer;

    }
}

.gird:nth-child(1) {
    background-image: url('../../res/all_cate.jpg');
    background-size: 100% 100%;
    align-items: center;

    &:hover {
        color: #FFF;

    }
}

.gird:nth-child(2) {
    background-image: url('../../res/kid_catewebp.webp');
    background-size: 100% 100%;

    &:hover {
        color: black;

    }
}

.gird:nth-child(3) {
    background-image: url('../../res/women_cate.webp');
    background-size: 100% 100%;

    &:hover {
        color: black;

    }
}

.gird:nth-child(4) {
    background-image: url('../../res/nu_cate.webp');
    background-size: 100% 100%;

    &:hover {
        color: black;

    }
}

.Section_three {
    padding-top: 1%;
    width: 99.2vw;
    height: 80vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
    flex-direction: row-reverse;
    overflow: hidden;
    font-style: italic;
    
}

.Section_three_img_holder {
    margin-top: 1%;
    width: 70%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px 0px 0px 20px;
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    filter: drop-shadow(-20px 9px 15px #c0bebe);
    /* box-shadow:(5% 5% 5% 5% #c0bebe); ; */
    background-color: #016505;
}

.Section_three_img_holder img {

    border-radius: 10px;
    width: 90%;
}

.Section_three_text_wrapper {
    margin-top: 2%;
    width: 80%;
    padding: 0 100px;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1%;
    font-size: 1.2vw;
    background-color: transparent;
    text-align: justify;
    /* border: black 2px solid; */

}

.Section_three_text_wrapper h1 {
    color: black;
    font-weight: 000;
    margin-bottom: 2%;
    background-color: transparent;

}

.Section_three_text_wrapper h2 {
    color: #016505;
    font-weight: bold;
}

.Section_three_text_wrapper h3 {
    /* font-size: 1.4rem; */
    width: 100%;
    font-weight: 500;
    margin-bottom: 2%;
    line-height: 1.5;
    color: black;
    background-color: transparent;
}

.Section_three_text_wrapper button {
    margin-top: 2%;
    width: 210px;
    height: 40px;
    font-size: 1.2vw;
    font-weight: 600;
    color: #FFF;
    border-radius: 15px;
    border: none;
    background-color: #016505;
}

.Section_three_text_wrapper button:hover {
    border: #016505 3px solid;
    background-color: #FFF;
    color: #016505;
    cursor: pointer;

}


@media screen and (max-width: 767px) {
    

    /* CSS styles for screens smaller than 768px */
    /* ... */
    @keyframes floating {
        0% {
            transform: translate(0, 0px);
        }
    
        50% {
            transform: translate(0, 5px);
        }
    
        100% {
            transform: translate(0, -0px);
        }
    }


    .Section_one {
        width: 99.2vw;
        /* border: 2px solid red; */
        height: 60vh;
    }

    .home_img_holder {
        /* border: 2px solid black; */
        width: 100%;
        height: 50%;
        aspect-ratio: 16/9;
    }

    .button_holder {
        width: 30%;
        height: 4%;
        top: 55%;
        left: 35%;
        
    }
    .button_holder button{
        font-size: 1.8vh;
    }
    .Section_two{
        width: 100vw;
        height: 50vh;
        margin-top:-15%
    }
    .grid_layout_wrapper{
        width: 100vw;
        /* height: 100vh; */
    }
    .gird{
        width: 40%;
    }
    .Section_three{
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        margin-bottom: 20%;
    }
    .Section_three_img_holder{
        width: 95%;
        height: 100%;
        border-radius: 2%;
        margin-bottom: 2%;
    }
    .Section_three_text_wrapper{
        margin-top: 2%;
        font-size: 1.6vh;
    }
    .Section_three_text_wrapper button{
        font-size: 2.3vh;
    }
}