.Main_container{
    width: 100%;
    height: 90vh;
    /* border: red 2px solid; */
    overflow: hidden;
    align-content: center;
}
.content_wrapper{
    height: 95%;
    width: 100%;
    /* border: 2px solid green; */
    display: flex;
    flex-direction: row;
}
.content_left{
    width: 45%;
    height: 100%;
    /* border: 2px solid red; */
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    justify-content: space-evenly;
}
.content_left img{
    width: 100%;
    height: 60%;
    object-fit: fill;
}
.content_right{
    width: 55%;
    height: 100%;
    /* border: green 2px solid; */
    overflow: scroll;
    padding-left: 1%;
}
.content_right img{
    width: 100%;
}
.content_right p{
    font-size: 1.2rem;
}