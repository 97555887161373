.Product_container {
    width: 99.2vw;
    height: 100vh;
    display: flex;
    background-color: #FFF;
    overflow: hidden;
    flex-direction: column;
    /* flex-wrap: wrap; */
}

.filter_wrapper {
    display: flex;
    width: 70%;
    border-bottom: 3px solid #016505;
    gap: 10%;
    margin: 0.5% 15%;
    /* padding: 1%; */
    justify-content: center;
    -webkit-user-select: none;

}

.filter_wrapper h2 {
    font-size: 1.6vw;
}

.filter_wrapper h2:hover {
    color: #016505;
    cursor: pointer;
}

.Product_layout_wrapper{
    width: 100%;
    height: 75%;
    /* border: 1px solid red; */
    display: flex;
    overflow: scroll;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2%;
    /* justify-content: space-around; */
}

#prodct_layout{
    border: 0.2px solid black;
    width: 20vw;
    height: 30vh;
    display: flex;
    margin: 5%;
    /* padding-right: 15%; */
    flex-direction: column;
    box-shadow: 10px 5px 10px grey;
    border-radius: 5px;
    overflow: hidden;
    transition: all .3s ease-in-out;
    &:hover {
        transform: scale(1.1);
        cursor: pointer;

    }
}
/* #prodct_layout:hover{} */

.product_layout_part_one{
    background-color: #016505;
    width: 20vw;
    height: 80%;
    /* border: 2px solid royalblue; */
    overflow: hidden;

}
.product_layout_part_one img{
    width: 100%;
    height: 100%;
    object-fit: fill;

}
.product_layout_part_two{
    display: flex;
    width: 100%;
    height: 20%;
    font-size: 0.9rem;
    background-color: #016505;
    color: #FFF;
    /* border: #016505 2px solid; */
    justify-content: center;
    align-items: center;
}


/* 
@media screen and (max-width: 767px){

} */